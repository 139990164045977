/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    tv: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 13.5A.5.5 0 013 13h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5M13.991 3l.024.001a1.5 1.5 0 01.538.143.76.76 0 01.302.254c.067.1.145.277.145.602v5.991l-.001.024a1.5 1.5 0 01-.143.538.76.76 0 01-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.5 1.5 0 01-.538-.143.76.76 0 01-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.5 1.5 0 01.143-.538.76.76 0 01.254-.302C1.498 3.078 1.675 3 2 3zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2"/>',
    },
});
